import React from "react";
import Helmet from "react-helmet";

import Layout from '../components/layout';

import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'

class Homepage extends React.Component {
    render() {
        const siteTitle = "Gatsby Starter - Photon";

        return (
            <Layout>

                <Helmet>
                    <title>React Theme - Photon</title>
                    <meta name="description" content="React theme built on top of Photon design (HTML5 Up) by @AppSeed" />
                </Helmet>

                <section id="one" className="main style1">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <header className="major">
                                <h2>React Theme Features</h2>
                            </header>

                            <p>
                            Open-Source <strong>React Theme</strong>. Get it, use it in your projects.  
                            <br /><br />
                            Development stack: <br  /> 
                            <a target="_blank" rel="noopener noreferrer" href="https://www.gatsbyjs.org">GatsbyJS,</a>{'  '}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.npmjs.com/package/ftp-deploy">Ftp-Deploy,</a>{'  '} 
                            <a target="_blank" rel="noopener noreferrer" href="https://www.gatsbyjs.org/packages/gatsby-plugin-google-analytics/">Analytics Plugin,</a>{'  '}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.gatsbyjs.org/packages/gatsby-plugin-sitemap/">Sitemap Plugin</a>
                            </p>

                        </div>
                        <div className="col-6">
                            <span className="image fit"><img src={pic01} alt="" /></span>
                        </div>
                    </div>
                </section>

                <section id="two" className="main style2">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <ul className="major-icons">
                                <li><span className="icon style1 major fa-code"></span></li>
                                <li><span className="icon style2 major fa-bolt"></span></li>
                                <li><span className="icon style3 major fa-camera-retro"></span></li>
                                <li><span className="icon style4 major fa-cog"></span></li>
                                <li><span className="icon style5 major fa-desktop"></span></li>
                                <li><span className="icon style6 major fa-calendar"></span></li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <header className="major">
                                <h2>Information</h2>
                            </header>
                            <p>
                                Coded in React<br/>
                                A modern React Theme Stack coded in React / GatsbyJS. Enhanced by AppSeed with: Google Analytics, Sitemap, FTP Deploy Script
                            </p>

                            <p>
                                Open-Source<br/>
                                The Source Code can be downloaded and used directly in your private or commercial projects.
                            </p>

                            <p>
                                HTML5 Up Design<br/>
                                State of the art design, modular and easy to customize. Released under permissive license
                            </p>

                        </div>
                    </div>
                </section>

                <section id="three" className="main style1 special">
                    <div className="grid-wrapper">
                        <div className="col-12">
                            <header className="major">
                                <h2>Photon React Theme</h2>
                            </header>
                            <p>Crafted by Html5 Up. Released under Creative Common 3.0 License</p>
                        </div>

                        <div className="col-4">
                            <span className="image fit"><img src={pic02} alt="" /></span>
                            <h3>Magna feugiat lorem</h3>
                            <p>Adipiscing a commodo ante nunc magna lorem et interdum mi ante nunc lobortis non amet vis sed volutpat et nascetur.</p>
                            <ul className="actions">
                                <li><a href="#" className="button">More</a></li>
                            </ul>
                        </div>
                        <div className="col-4">
                            <span className="image fit"><img src={pic03} alt="" /></span>
                            <h3>Magna feugiat lorem</h3>
                            <p>Adipiscing a commodo ante nunc magna lorem et interdum mi ante nunc lobortis non amet vis sed volutpat et nascetur.</p>
                            <ul className="actions">
                                <li><a href="#" className="button">More</a></li>
                            </ul>
                        </div>
                        <div className="col-4">
                            <span className="image fit"><img src={pic04} alt="" /></span>
                            <h3>Magna feugiat lorem</h3>
                            <p>Adipiscing a commodo ante nunc magna lorem et interdum mi ante nunc lobortis non amet vis sed volutpat et nascetur.</p>
                            <ul className="actions">
                                <li><a href="#" className="button">More</a></li>
                            </ul>
                        </div>

                    </div>
                </section>

                <section id="four" className="main style2 special">
                    <div className="container">
                        <header className="major">
                            <h2>AppSeed</h2>
                        </header>
                        <p>Full-Stack Web App generator. Build React, Vue.js app in minutes</p>
                        <ul className="actions uniform">
                            <a href="https://appseed.us" className="button special">Build React Apps</a> {' '}
                            <a href="https://blog.appseed.us/react-themes-dashboards-landing-pages/" className="button">More React Themes</a>
                        </ul>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default Homepage;